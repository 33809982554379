<template lang="pug">
div.flex.flex-col.items-center.max-w-screen-xl.px-6.mx-auto.py-8
  .text-center
    h2.text-4xl.font-bold.text-center {{ forgotPassword ? 'Mot de passe oubli&eacute;': 'Se connecter' }}
  div(class="mt-8 w-full flex justify-center")
    form(@submit.prevent="" class="w-full sm:w-1/2")
      div.flex.flex-col
        label(for="email" class="block text-sm font-bold asterisque") {{ forgotPassword ? 'E-mail d\'inscription': 'Nom d\'utilisateur' }}
        input(type="text" v-model="username" autocomplete="username" name="email" id="email" :class="[hasError && !username ? 'border-2 border-red' : 'border-black'], 'focus:border-primary focus:ring-primary focus:outline-none focus:ring'").block.w-full.p-4.mt-2.text-gray-700.bg-white.border.rounded-md
        span(class="text-xs text-gray-400") Saisissez votre {{ forgotPassword ? 'e-mail d\'inscription': 'nom d\'utilisateur' }}  pour 3ifa

      div(v-if="resetRequested" :class="forgotPassword && !resetRequested ? '' : 'mt-6'").flex.flex-col
        .flex.justify-between.mb-2.items-end
          label(for="token" class="text-sm font-bold asterisque") Code de r&eacute;initialisation
        input(type="text" v-model="token" name="token" id="token" :class="[hasError && !token ? 'border-2 border-red' : 'border-black'], 'focus:border-primary focus:ring-primary focus:outline-none focus:ring'").block.w-full.p-4.bg-white.border.rounded-md
    
      div(v-if="!forgotPassword || resetRequested" :class="forgotPassword && !resetRequested ? '' : 'mt-6'").flex.flex-col
        .flex.justify-between.mb-2.items-end
          label(for="password" class="text-sm font-bold asterisque") Mot de passe
          input(v-if="!forgotPassword" type="button" value="Mot de passe oublié ?" @click="forgotPassword = true" class="text-xs font-normal text-primary hover:underline underline-offset-2")
        input(v-if="!forgotPassword || resetRequested" type="password" v-model="password" autocomplete="current-password" name="password" id="password" :class="[hasError && !password ? 'border-2 border-red' : 'border-black'], 'focus:border-primary focus:ring-primary focus:outline-none focus:ring'").block.w-full.p-4.bg-white.border.rounded-md
        span(v-if="!forgotPassword || resetRequested" class="text-xs text-gray-400 mt-1") Saisissez votre mot de passe {{ !forgotPassword || resetRequested ? '' : 'correspondant à votre nom d\'utilisateur' }} 
        .flex.justify-between.mb-2.items-end(v-if="resetRequested")
          label(for="repeatPassword" class="text-sm font-bold asterisque") R&eacute;p&eacute;ter votre mot de passe
        input(v-if="resetRequested" type="password" v-model="repeatPassword" name="repeatPassword" id="repeatPassword" :class="[!repeatPassword || password !== repeatPassword ? 'border-2 border-red' : 'border-black'], 'focus:border-primary focus:ring-primary focus:outline-none focus:ring'").block.w-full.p-4.bg-white.border.rounded-md
        span(v-if="resetRequested" class="text-xs text-gray-400 mt-1") Confirmez votre mot de passe
      
      div(v-if="hasError").mt-6.text-red.flex.space-x-2.items-center
        ExclamationCircleIcon.h-5.w-5
        span {{ error }}
      div(class="hover:bg-[#C44812]").bg-primary.text-white.rounded-md.boxShadow.cursor-pointer.mt-6
        button(type="submit" :disabled="displayLoader" @click="login" class="relative").p-4.w-full.tracking-wide.transition-colors.duration-200.transform.rounded-md
          | {{ forgotPassword ? 'Réinitialiser': 'Se connecter' }}
          span(v-if="displayLoader" class="loader absolute top-5 right-8")
      div(class="hover:bg-[#C44812] hover:text-white").border-2.border-primary.rounded-md.boxShadow.cursor-pointer.mt-6
        button(type="submit" @click="annuler").p-4.w-full.tracking-wide.transition-colors.duration-200.transform.rounded-md Annuler
</template>

<script setup>
  import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

  useHead({
    title: 'Login'
  })

  const route = useRoute()
  const destination = route.query['destination'] ?? undefined

  const redirect = () => {
    if (destination) {
      window.location.replace(destination)
    } else {
      navigateTo(route.redirectedFrom || '/')
    }
  }

  const error = ref()
  const username = ref()
  const password = ref()
  const token = ref()
  const success = ref(false)
  const reload = ref(false)
  const repeatPassword = ref()
  const displayLoader = ref(false)
  const resetRequested = ref(false)
  const forgotPassword = ref(false)
  const message = ref(
    'Votre demande a été enregistrée. Consultez votre boîte mail pour le code de réinitialisation.'
  )

  const hasError = ref(false)

  const annuler = () => {
    redirect()
  }

  const login = async () => {
    error.value = ''
    hasError.value = false
    displayLoader.value = true

    if (resetRequested.value) {
      return resetPassword()
    }

    if (forgotPassword.value) {
      return resquestReset()
    }

    const { error: err } = await useLogin(
      username.value,
      password.value,
      annuler
    )
    if (err) {
      hasError.value = true
      displayLoader.value = false
      switch (err.value) {
        case 'Sorry, unrecognized username or password.':
          error.value = "Nom d'utilisateur ou mot de passe non reconnu"
          break

        default:
          error.value = err.value
          break
      }
    }
    if (!err) {
      displayLoader.value = false
    }
  }

  const resquestReset = async () => {
    if (isEmailValid(username.value)) {
      const resp = await apiMutate('/user/lost-password?_format=json', {
        mail: username.value,
      })
      displayLoader.value = false
      resetRequested.value = true
      success.value = true
    } else {
      displayLoader.value = false
      hasError.value = true
      error.value = 'E-mail non valide'
    }
  }

  const resetPassword = async () => {
    if (
      isRequiredValid(username.value) &&
      isRequiredValid(password.value) &&
      isRequiredValid(repeatPassword.value) &&
      password.value === repeatPassword.value
    ) {
      const resp = await apiMutate('/user/lost-password-reset?_format=json', {
        name: username.value,
        new_pass: password.value,
        temp_pass: token.value,
      })
      displayLoader.value = false
      message.value =
        'Votre mot de passe a été réinitialisé. Veuillez vous connecter.'
      success.value = true
      reload.value = true
    } else {
      displayLoader.value = false
      hasError.value = true
      error.value = 'Veuillez corriger les erreurs signalées'
    }
  }
</script>

<style scoped>
  .asterisque::after {
    content: '*';
    margin: 0 0.15rem;
    color: #dc2323;
    font-size: 0.875rem;
  }

  .boxShadow {
    box-shadow: 0 1px 2px 0 rgba(5, 80, 230, 0.15);
  }
</style>
